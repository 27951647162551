import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndividualWave = ({ data }) => {
    let waveNum = data.node.name.split(' #')[1];
    let liveUrl = '/wave-' + waveNum;
    let mobilePicUrl =  "https://d28a5q050a9bu1.cloudfront.net/images/waves/real-waves/" + waveNum + "+.png";

    return (
        <div className='left collection-item'>
            <Link className='image' to={liveUrl} style={{ backgroundImage: `url(${mobilePicUrl})`}} alt={`Wave #${waveNum}`}></Link>

            <div className='details'>
                <h4>{data.node.name}</h4>
                <Link className='button black detailed-view' to={liveUrl}>View</Link>
            </div>
        </div>
    )
}

const IndividualEpoch = ({ data }) => {
  let epochNum = data.node.name.split(' #')[1];
  let liveUrl = '/epoch-' + epochNum;
  let mobilePicUrl =  "https://d28a5q050a9bu1.cloudfront.net/images/small-site-epochs/smaller-website-sizes/" + epochNum + ".png";

  return (
      <div className='left collection-item'>
          <Link className='image' to={liveUrl} style={{ backgroundImage: `url(${mobilePicUrl})`}} alt={`Wave #${epochNum}`}></Link>

          <div className='details'>
              <h4>{data.node.name}</h4>
              <Link className='button black detailed-view' to={liveUrl}>View</Link>
          </div>
      </div>
  )
}

const PaginatedCollection = ({ data, RenderComponent }) => {
    let collection = data;

    return (
        <div>
            {collection.map((d, idx) => (
                <RenderComponent key={idx} data={d} />
            ))}
        </div>
    )
}

const PlaygroundsDao = ({ data }) => {
  const waves = data.DAOWavesQuery.edges.sort((a, b) => (parseInt(a.node.name.substring(6))) > parseInt(b.node.name.substring(6)) ? 1 : -1);
  const epochs = data.DAOEpochsQuery.edges.sort((a, b) => (parseInt(a.node.name.substring(7))) > parseInt(b.node.name.substring(7)) ? 1 : -1);

  return (
    <Layout>
        <Seo title="PlaygroundsDAO" />
        <div className='container clearfix'>
            <section className='home-intro dao'>
                <h1>Welcome to PlaygroundsDAO,<br/>the first NFT DAO focused on art on Solana.</h1>
                <div className='clearfix'>
                    <div className='col40 left info-box'>
                        <h2>Join PlaygroundsDAO</h2>
                        <p className='big'>PlaygroundsDAO is currently exclusive to Playground Wave & Epoch holders.</p>
                        <div><a href="https://discord.gg/qnqrAVeHu7" className='button white arrow'>Join Us</a></div>
                    </div>
                    <div className='col40 left info-box'>
                        <h2>Don't have a Wave or an Epoch?</h2>
                        <p className='big'>Buy your first from one of the marketplaces that Playground collections are listed on.</p>
                        <div><Link to="/marketplaces/" className='button white arrow'>Marketplaces</Link></div>
                    </div>
                </div>
            </section>
        </div>
        <div className='container clearfix dao-collections'>
            <div className='section-title clearfix'>
                <h2>Playground's Permanent Collection</h2>
                <p className='big'>PlaygroundsDAO seeks to acquire, safeguard, and permanently hold art from artists we collaborate with for the purposes of exhibiting, presenting, and educating the public at large about Solana NFTs and the new medium of digital NFT art.</p>
                <p className='big'>Below are the works included in the permanent collection so far:</p>
                <Link className='button arrow transparent' to="/waves-gallery/">Waves</Link>
                <div className='collections-list dao'>
                    <PaginatedCollection
                        data={waves}
                        RenderComponent={IndividualWave}
                    />
                </div>
                <Link className='button arrow transparent' to="/epochs-gallery/">Epochs</Link>
                <div className='collections-list dao'>
                    <PaginatedCollection
                        data={epochs}
                        RenderComponent={IndividualEpoch}
                    />
                </div>
            </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
    query {
      DAOWavesQuery: allSpecificWavesJson(filter: {treasury: {eq: true}}) {
        edges {
            node {
            id
            attributes {
                hash_id
                Number_of_colors
                brightness
                speed
                saturation
                alpha
            }
            name
            }
        }
    }
    DAOEpochsQuery: allEpochsJson(filter: {treasury: {eq: true}}) {
      edges {
          node {
          id
          name
          }
      }
      
  }
  }
`

export default PlaygroundsDao
